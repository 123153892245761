// @ts-strict-ignore
import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import moment from "moment"
import styled, { css } from "styled-components"

import { Heading, Separator, TextLink } from "@kiwicom/orbit-components"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import Container from "components/container"
import { NewsTag } from "components/generic/news-tag"
import Hero from "components/hero"
import Layout from "components/layout"

const Page = () => {
  return (
    <Layout title="News" description="Find out what's new at Ember">
      <Hero title="News & Updates">
        <SubheadingWrapper>
          <Heading spaceAfter="large" align="center" type="displaySubtitle">
            Find out what's new at Ember
          </Heading>
        </SubheadingWrapper>
      </Hero>
      <News />
    </Layout>
  )
}

const News = () => {
  // @ts-expect-error: the CI build is missing `gatsby-types.d.ts`
  // See https://github.com/gatsbyjs/gatsby/discussions/36220
  const data = useStaticQuery<Queries.NewsQuery>(graphql`
    query News {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "news" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              date
              headline
              newsCategory
            }
            html
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  return (
    <Container size="medium">
      <MainGrid>
        {posts.map((item, index) => {
          const post = item.node
          const headline = post.frontmatter.headline

          const idHref = `${post.frontmatter.headline
            .toLowerCase()
            .replaceAll(" ", "-")}-${moment(post.frontmatter.date)
            .format("D MMM YYYY")
            .replaceAll(" ", "-")}`

          return (
            <>
              <ArticleGrid id={idHref} style={{ scrollMarginTop: "65px" }}>
                <div></div>
                <div>
                  <NewsTag category={post.frontmatter.newsCategory} />
                </div>
                <TextLink type="secondary" noUnderline href={`#${idHref}`}>
                  <Date>
                    {moment(post.frontmatter.date).format("D MMM YYYY")}
                  </Date>
                </TextLink>
                {headline && (
                  <HeadlineWrapper>
                    <Heading type="title2">{post.frontmatter.headline}</Heading>
                  </HeadlineWrapper>
                )}
                <Body dangerouslySetInnerHTML={{ __html: post.html }} />
              </ArticleGrid>
              {index < posts.length - 1 && (
                <SeparatorWrapper>
                  <Separator />
                </SeparatorWrapper>
              )}
            </>
          )
        })}
      </MainGrid>
    </Container>
  )
}

const SubheadingWrapper = styled.div`
  margin-top: 10px;
`

const Date = styled.div`
  font-weight: bold;
  min-width: 100px;
`

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  ${media.tablet(css`
    grid-column: 2;
    grid-template-columns: 140px 1fr;
  `)}
`

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px 40px;
  align-items: start;
  ${media.tablet(css`
    grid-column: span 2;
    grid-template-columns: 140px 1fr;
  `)}
`

const HeadlineWrapper = styled.div`
  ${media.tablet(css`
    grid-column: 2;
  `)}
`

const Body = styled.div`
  > p:last-child {
    margin: 0;
  }
  ${media.tablet(css`
    grid-column: 2;
  `)}
`

const SeparatorWrapper = styled.div`
  height: 1px;
  ${media.tablet(css`
    grid-column: 2;
  `)}
`

export default Page
