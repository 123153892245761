import React from "react"

import styled from "styled-components"

interface NewsTagProps {
  category: string
}

const NewsTag = ({ category }: NewsTagProps) => (
  <StyledNewsTag category={category}>{category}</StyledNewsTag>
)

const StyledNewsTag = styled.div<NewsTagProps>`
  background: ${(props) => {
    if (props.category == "company") {
      return props.theme.orbit.paletteOrangeNormal
    } else if (props.category == "route") {
      return props.theme.orbit.paletteGreenNormal
    } else if (props.category == "feature") {
      return props.theme.orbit.paletteBlueNormal
    } else if (props.category == "onboard") {
      return props.theme.orbit.paletteRedNormal
    } else if (props.category == "new") {
      return props.theme.orbit.paletteBlueNormal
    } else {
      return props.theme.orbit.paletteBlueNormal
    }
  }};
  color: white;
  display: inline-block;
  padding: 5px 8px;
  border-radius: 4px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
`

export { NewsTag }
